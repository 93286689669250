// extracted by mini-css-extract-plugin
export var content = "sidebar-module--content--3Qt8f";
export var sidebarContent = "sidebar-module--sidebarContent--1OobT";
export var profileAvatar = "sidebar-module--profileAvatar--3ZCP6";
export var name = "sidebar-module--name--1MO91";
export var badge = "sidebar-module--badge--3nVkT";
export var badgeGray = "sidebar-module--badgeGray--1GMy7";
export var boxName = "sidebar-module--boxName--sl8d8";
export var contactBlock = "sidebar-module--contactBlock--cKeoN";
export var contactBlockItem = "sidebar-module--contactBlockItem--D-BUt";
export var resumeDownload = "sidebar-module--resumeDownload--3lYdq";
export var emailHider = "sidebar-module--emailHider--1kiQB";
export var background = "sidebar-module--background--1n6nA";
export var boxContent = "sidebar-module--boxContent--1A524";
export var sideBar404Radius = "sidebar-module--sideBar404Radius--2p_qY";